import { forwardRef } from 'react'
import { Pulse } from '../../types'
import { Box, BoxProps } from '../Box'
import useShowTime from '../../utils/common'
import { ClockIcon, LinkIcon } from '../../icons'

interface PulseItemProps extends BoxProps {
  pulse: Pulse
  isFullList?: boolean
}

const PulseItem = forwardRef<HTMLDivElement, PulseItemProps>((props, ref) => {
  const { pulse, isFullList = true, ...rest } = props
  const baseUnit = '8px'

  return (
    <Box ref={ref} {...rest}>
      <Box
        as={'a'}
        href={`/pulses/${pulse.id}`}
        target='_blank'
        display={'block'}
        style={{
          margin: '-12px',
          marginTop: '-24px',
          marginBottom: '0',
          marginLeft: '-24px',
          padding: '12px',
          borderRadius: '8px',
          transition: 'all 0.3s ease-in-out'
        }}
        _hover={{
          backgroundColor: 'rgba(0,0,0,0.25)'
        }}
      >
        <Box
          as='h3'
          fontSize={isFullList ? `calc(${baseUnit}*2.25/16*16)` : `calc(${baseUnit}*2/16*16)`}
          lineHeight={1.25}
          fontWeight={500}
          mb={'8px'}
          _before={{
            content: '""',
            display: 'block',
            position: 'absolute',
            top: '8px',
            left: '-34px',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: 'var(--chakra-colors-sectionTitle)',
            zIndex: 2
          }}
          _after={{
            content: '""',
            display: 'block',
            position: 'absolute',
            top: '4px',
            left: '-38px',
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            backgroundColor: 'var(--chakra-colors-sectionTitle)',
            opacity: 0.2,
            zIndex: 2
          }}
        >
          {pulse.title.rendered}
        </Box>
        <Box display={'flex'} mb={'8px'}>
          <Box color='rgba(255,255,255,0.5)' fontSize={'14px'} display={'flex'} alignItems={'center'} mr={'12px'}>
            <Box mr={'8px'}>
              <ClockIcon width={'14px'} height={'14px'} color='rgba(255,255,255,0.5)' />
            </Box>{' '}
            {useShowTime(pulse.date)}
          </Box>

          {pulse.source && !pulse.source_link && (
            <Box color='rgba(255,255,255,0.5)' fontSize={'14px'} display={'flex'} alignItems={'center'} mr={'8px'}>
              <Box mr={'8px'}>
                <LinkIcon width={'14px'} height={'14px'} color='rgba(255,255,255,0.5)' />
              </Box>
              {pulse.source}
            </Box>
          )}

          {pulse.source && pulse.source_link && (
            <Box color='rgba(255,255,255,0.5)' fontSize={'14px'} display={'flex'} alignItems={'center'} mr={'8px'}>
              <Box mr={'8px'}>
                <LinkIcon width={'14px'} height={'14px'} color='rgba(255,255,255,0.5)' />
              </Box>
              <Box as='a' href={pulse.source_link} target='_blank'>
                {pulse.source}
              </Box>
            </Box>
          )}
        </Box>
        <Box
          as='p'
          dangerouslySetInnerHTML={{ __html: pulse.content }}
          style={
            !isFullList
              ? {
                  fontSize: `calc(${baseUnit}*2/16*15)`,
                  lineHeight: `calc(${baseUnit}*2/16*15*1.5)`,
                  // lineClamp: 3,
                  // WebkitLineClamp: 3,
                  // WebkitBoxOrient: 'vertical',
                  // textOverflow: 'ellipsis',
                  // overflow: 'hidden',
                  // display: '-webkit-box',
                  color: 'rgba(255,255,255,0.9)'
                }
              : {
                  fontSize: `calc(${baseUnit}*2/15*15)`,
                  lineHeight: `calc(${baseUnit}*2/14*15*1.75)`,
                  color: 'rgba(255,255,255,0.9)'
                }
          }
        />
      </Box>
    </Box>
  )
})

PulseItem.displayName = 'PulseItem'

export default PulseItem
