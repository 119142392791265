import { Pulse } from '@krasia/core/types'
import PulseItem from './PulseItem'
import { Box } from '../Box'

interface PulsesListProps {
  list: Pulse[]
  isFullList?: boolean
}

const PulsesList: React.FC<PulsesListProps> = ({ list, isFullList }) => {
  return (
    <Box
      marginLeft={'12px'}
      paddingLeft={'30px'}
      // // borderLeft={'1px dashed var(--chakra-colors-sectionTitle)'}
      borderLeft={'1px dashed rgba(102,252,241, 0.3)'}
    >
      {list.map(pulse => (
        <PulseItem
          key={pulse.id}
          pulse={pulse}
          isFullList={isFullList}
          style={{
            marginBottom: '24px',
            position: 'relative',
            borderBottom: '1px dashed rgba(255,255,255,0.1)',
            padding: '12px',
            paddingBottom: '24px'
          }}
          _last={{
            // background: '#f00',s
            borderBottom: 'none !important',
            paddingBottom: '0 ',
            marginBottom: '0  !important'
          }}
          // _before={{
          //   content: '""',
          //   display: 'block',
          //   position: 'absolute',
          //   left: '-42px',
          //   width: '24px',
          //   height: '24px',
          //   borderRadius: '50%',
          //   backgroundColor: 'var(--chakra-colors-bg)',
          //   zIndex: 1
          // }}
          _first={{
            marginTop: isFullList ? '0' : '12px'
          }}
        />
      ))}
    </Box>
  )
}

export default PulsesList
